<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="wrap">
      <div class="h1">
        共筑医疗质控健康防线，推动放射影像高质发展
      </div>
      <div class="h2">
        国家级放射影像专业质控中心X线技术亚专业组第一次会议成功举行
      </div>
      <section>
        <div class="div_p">
          在以新质生产力创新为主导的新时代，高科技、高效能、高质量作为推动发展内在需求，是实现突破的重要着力点。构建系统、规范的医疗质控指标体系对加强科学化、精细化医疗质量管理，促进医疗质量持续改进具有重要且深远的意义。放射影像专业专项质控，
          X线技术首当其冲，在国家级放射影像专业质量控制中心的引领下，X线技术亚专业指标专家工作组成立，由吉林大学第一医院放射线科张惠茅教授担任组长、河北医科大学第二医院刘怀军教授、青岛大学附属医院医学影像中心徐文坚教授、中国医科大学附属第一医院放射科孙文阁教授、贵州省人民医院放射科王荣品教授、北京积水潭医院程晓光教授、吉林大学中日联谊医院放射科柳林教授担任副组长及14名组员、1名秘书构成。
        </div>
        <div class="div_p">
          2024年6月25日下午，在亚专业组的精心组织下第一次线上工作会议顺利召开，国家级放射影像专业质控中心主任委员金征宇教授、副主任委员薛华丹教授、秘书孙昊教授和于静老师亲临指导，并为亚专业组的工作提供了宝贵的方向指引。国家级放射影像质控中心委员、X线技术亚专业组组长张惠茅教授同亚专业组全体专家成员参加会议，会议由秘书张磊主持。专家们云端齐聚，围绕国家级质控中心工作计划及X线亚专业组工作安排进行了积极且深入的交流与讨论。
        </div>
        <div class="div_p">
          国家级放射影像专业质控中心主任委员金征宇教授在致辞中强调了放射影像质控工作对于医疗行业高质量发展的重要性，并着重指出国家级质控中心与协会、学组不同，要注重落实和加强医疗质量控制指标核心内涵的理解及持续改进。金征宇教授同时对X线技术亚专业组接下来的工作提出殷切希望，希望在组长张惠茅教授的带领下，大家团结一致，把X线技术质控工作推上新台阶。
        </div>
        <div class="div_p">
          国家级放射影像质控中心X线技术亚专业组组长张惠茅教授在致辞中向国家级质控中心领导对亚专业组的信任与支持表示了衷心的感谢，同时，也对组内专家成员在亚专业组工作中积极参与和付出表示深深的敬意。X线检查面临萎缩的挑战，但其应用的普遍性使其在不同级别医院甚至社区医院中仍具有不可替代的地位，做好质控工作对提高放射影像领域整体专业水平具有重要意义。人工智能作为新质生产力，AI赋能质控将会做为未来推进的方向。
        </div>
        <div class="div_p">
          孙昊教授作为国家级放射影像质控中心秘书，分享了中心成立的背景和发展历程，强调完善组织体系建设、推进指标体系工作的重要性，并对放射影像专业医疗质量控制指标内涵进行解读，重点介绍了质控组织体系建设、NCIS放射影像质控指标的调研和分析，以及未来新型工作模式，为共同促进放射质量管理规范化、质量水平同质化的发展指明了方向。
        </div>
        <div class="div_p">
          张惠茅教授作为X线技术亚专业组的组长，详细介绍了亚专业组的工作目标、内容和计划，结合会前对组内专家进行的充分调研，部署安排了两项近期重点工作，顾雅佳教授牵头乳腺钼靶BI-RADS质控指标培训视频准备及刘怀军教授、徐文坚教授、孙文阁教授、王荣品教授、程晓光教授、柳林教授牵头进行各系统X线检查的行业规范调研。接下来，亚专业组将围绕国家级质控中心要求，积极开展工作，推进组内任务的顺利完成。
        </div>
        <div class="div_p">
          会议沟通环节气氛热烈，多位专家围绕工作的开展提出了宝贵建议和积极反馈。国家级放射影像质控中心副主任薛华丹教授就大家提出的问题进行了详细的解答，并结合实际帮助大家梳理了工作思路，传达了国家层面精神，对亚专业组后续工作的开展给予了积极的引导。
        </div>
        <div class="div_p">
          积极响应国家级质控中心号召，X线技术亚专业组率先开展工作，通过本次工作会议的召开，亚专业组明确了工作方向。在各位领导和专家的共同努力下，X线技术亚专业组将全力高质量完成国家级质控中心布置的工作，共同推动亚专业质控指标的制定、培训和实施，令质控指标更兼具科学性、合理性和可操作性。助力提升医疗质量管理的科学化和精细化水平，促进放射影像专业质控向规范化、同质化、智能化的新时代迈进。
        </div>
        <div>
          <img src="@/assets/HY20240701/1.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20240701/2.png" alt="">
        </div>
        <div class="div_tit">
          全体参会人员
        </div>
        <div>
          <img src="@/assets/HY20240701/3.png" alt="">
        </div>
        <div class="div_tit">
          国家级放射影像专业质控中心主任委员 金征宇教授
        </div>
        <div>
          <img src="@/assets/HY20240701/4.png" alt="">
        </div>
        <div class="div_tit">
          国家级放射影像专业质控中心专家委员会委员 X线技术专业组组长 张惠茅教授
        </div>
        <div>
          <img src="@/assets/HY20240701/5.png" alt="">
        </div>
        <div class="div_tit">
          国家级放射影像专业质控中心副主任委员 薛华丹教授
        </div>
        <div>
          <img src="@/assets/HY20240701/6.png" alt="">
        </div>
        <div class="div_tit">
          国家级放射影像专业质控中心秘书 孙昊教授
        </div>

      </section>
    </div>

  </div>
</template>

<script>
export default {
  name: "HY20240701",
  data() {
    return {

    }
  },

}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
  margin: 10px 0;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.h2 {
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
</style>